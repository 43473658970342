import {Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2} from '@angular/core';
import {environment} from '../../environments/environment';
import {Stream} from '../models/stream';
import {LocalTakeVideo} from "../interfaces/local-take.interface";
import {isPlatformBrowser} from "@angular/common";

export interface Convertable {
    takeId: number;
    file: File | Blob;
    fileName: string;
}

@Injectable({
    providedIn: 'root',
})
export class FilesUtilityService {

    private _renderer: Renderer2;

    constructor(
        private rendererFactory: RendererFactory2,
        @Inject(PLATFORM_ID) private platformId: object
    ) {
        this._renderer = this.rendererFactory.createRenderer(null, null);
    }

    /**
     * Download a file
     * @param downloadUrl {string} The downloadable resource URL
     * @param downloadFileName {string} The downloadable name
     */
    download = (downloadUrl: string, downloadFileName: string) => {
        const anchorElement: HTMLAnchorElement = this._renderer.createElement('a');
        this._renderer.setAttribute(anchorElement, "target", "_blank");
        this._renderer.setAttribute(anchorElement, "href", downloadUrl);
        this._renderer.setAttribute(anchorElement, "rel", "noopener noreferrer");
        this._renderer.setAttribute(anchorElement, "download", downloadFileName);
        this._renderer.appendChild(document.body, anchorElement);
        anchorElement.click();
        anchorElement.remove();
    }


    fetchAndConvertVttToSrt = async (vttUrl: string) => {
        try {
            // Fetch the VTT content from the provided URL
            const response = await fetch(vttUrl);

            // Check if the fetch was successful (status code 200)
            if (!response.ok) {
                throw new Error(`Failed to fetch the VTT file from ${vttUrl}`);
            }

            // Read the VTT content as text
            const vttContent = await response.text();

            // Convert VTT to SRT
            const srtContent = vttContent.replace("WEBVTT", "").replace(/\./g, ",");

            const fixedSrt = this.addLineNumbersToSRT(srtContent);
            // Convert the SRT content to a Blob
            return new Blob([fixedSrt], {type: "text/plain"});
        } catch (error) {
            console.error("Error fetching and converting VTT to SRT:", error);
            throw error;
        }
    }

    private addLineNumbersToSRT(srtContent: string): string {
        // Split the SRT content into individual subtitle entries
        const subtitleEntries = srtContent.trim().split('\n\n');

        // Initialize a variable to store the modified SRT content
        let modifiedSRT = '';

        // Iterate through the subtitle entries and add line numbers
        for (let i = 0; i < subtitleEntries.length; i++) {
            const entryLines = subtitleEntries[i].split('\n');
            // Add the line number (i + 1) to the entry
            modifiedSRT += (i + 1).toString() + '\n';
            // Add the rest of the entry lines
            for (const line of entryLines) {
                modifiedSRT += line + '\n';
            }
            // Add an empty line between entries
            modifiedSRT += '\n';
        }

        return modifiedSRT;
    }

    downloadAssetWithUrl = (url: string, fileName: string, fileExtension?: string) => {
        const extension = fileExtension || url.split(".").slice(-1)[0];
        const downloadFileName = `${fileName}.${extension}`;
        const downloadUrl = this._getDownloadUrl(url, fileName, extension);
        this.download(downloadUrl, downloadFileName);
    }

    downloadFile = (file: File | Blob, fileName: string, extension?: string) => {
        const blobUrl = URL.createObjectURL(file);
        const blobType = file.type.split(";")[0];
        const blobExtension = extension || blobType.split("/")[1];
        this.download(blobUrl, `${fileName}.${blobExtension}`);
        URL.revokeObjectURL(blobUrl);
    }

    convertToMp4 = (takeVideo: LocalTakeVideo) => {
        if (isPlatformBrowser(this.platformId)) {
            window.open(`/download-mp4?id=${takeVideo.takeId}`, '_blank', `location=false,menubar=false,toolbar=false,width=768,height=800`);
        }
    }

    /**
     * Creates an Image blob video thumbnail
     * @param file {Blob} The video file
     * @param seekTo {number} The seek time on which to create the thumbnail
     */
    createVideoThumbnail = (file: Blob, seekTo: number = 0): Promise<Blob> => {
        return new Promise((resolve, reject) => {
            // load the file to a video player
            const videoPlayer = document.createElement('video');
            videoPlayer.setAttribute('src', URL.createObjectURL(file));
            videoPlayer.load();
            videoPlayer.addEventListener('error', _event => {
                reject("error when loading video file");
            });

            // load metadata of the video to get video duration and dimensions
            videoPlayer.addEventListener('loadedmetadata', () => {
                // seek to user defined timestamp (in seconds) if possible
                if (videoPlayer.duration < seekTo) {
                    reject("video is too short.");
                    return;
                }

                // delay seeking or else 'seeked' event won't fire on Safari
                setTimeout(() => {
                    videoPlayer.currentTime = seekTo;
                }, 200);
                // extract video thumbnail once seeking is complete
                videoPlayer.addEventListener('seeked', () => {
                    // define a canvas to have the same dimension as the video
                    const canvas = document.createElement("canvas");
                    canvas.width = videoPlayer.videoWidth;
                    canvas.height = videoPlayer.videoHeight;
                    // draw the video frame to canvas
                    const ctx = canvas.getContext("2d");
                    ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
                    // return the canvas image as a blob
                    ctx.canvas.toBlob(
                        blob => {
                            resolve(blob);
                        },
                        "image/jpeg",
                        0.75 /* quality */
                    );
                });
            });
        })
    }

    getVideoFileAttributes = (file: File | Blob): Promise<Partial<Stream>> => {
        return new Promise((resolve, reject) => {
            const videoPlayer = document.createElement('video');
            videoPlayer.setAttribute('src', URL.createObjectURL(file));
            videoPlayer.load();
            videoPlayer.addEventListener('loadedmetadata', event => {
                const videoElement = (<HTMLVideoElement>event.target);
                resolve({
                    url: videoElement.src,
                    width: videoElement.videoWidth,
                    height: videoElement.videoHeight,
                });
            });
        });
    }

    private _getDownloadUrl = (url: string, name: string, extension: string): string => {
        const nameWithoutSpaces = name.replace(/[^\w\d]/g, "_");
        return url
                .replace(environment.globals.cloudfrontAddress, environment.globals.downloadAddress) +
            `?filename=${nameWithoutSpaces}.${extension}`;
    }

}
